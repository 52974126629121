import React from 'react'
import Home from './Home'
import Contact from "./Contact"
import About from "./About"
import Tech from './Tech'


function Main () {

    return (
    <>
        <div id="Home" style={{height: 800}}>
            <Home />
        </div>
        <div id="about" style={{height: 800}}>
            <About />
        </div>
        <div id="tech" style={{height: 800}}>
            <Tech />
        </div>
        <div id="contact" style={{height: 800}}>
            <Contact />
        </div>
        
    </>
    )
}

export default Main;