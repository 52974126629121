import React from 'react';
import { Link } from 'react-scroll'

function Navbar() {

    return(
        <nav style={{position: 'sticky', top: 0, padding: 70, fontSize: 20}}>
                <ul style={{display: 'flex', listStyle: 'none', justifyContent: 'space-around'}}>
                    <li><Link activeClass="active" to="about" spy={true} offset={-200} smooth={true}>About</Link></li>
                    <li><Link to="tech" spy={true} offset={-200} smooth={true}>Technologies</Link></li>
                    <li><Link  to="contact" spy={true} smooth={true} offset={-200}>Contact</Link></li>
                </ul>
        </nav>
    )
}

export default Navbar