import React from 'react'
import Typed from 'react-typed';

const Home = () => {
    return (
      <Typed style={{fontSize: 50, padding: 100}}
      strings={[
          "Hi I'm Lauren, I'm a developer",
          "Hi I'm Lauren, I'm a skiier",
          "Hi I'm Lauren, I'm interested in energy"]}
          typeSpeed={80}
          backSpeed={50}
          loop >
      </Typed>
    );
  };



export default Home