import React from 'react'
import linkedInLogo from "../LI-Logo.png"


function Contact() {
    const handleClick = () => {
        window.open("https://www.linkedin.com/in/lauren-lisle-a900b434/");
      };

      return (
        <div id="contact" style={{height: 800}}>
            <h1>Contact</h1>
            <img className="logo" src={linkedInLogo} onClick={handleClick} alt="Linkedin Logo"/>
        </div>
      )
      }

export default Contact