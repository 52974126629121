import React from "react"

function Tech() {
    const styles = {
        "padding-left": "300px",
        "padding-right": "300px",
        "text-align": "left"
    }
    return (
        <div>
            <h1>Technologies</h1>
            <p>Python</p>
            <p>Typescript/Javascript</p>
            <p>React</p>
            <p>AWS Cloudformation/CDK</p>
            <p>Raspberry Pis/Linux</p>
            <p>Docker</p>
            <p>C#/MVC</p>
            <p>Dabble in Java</p>
            <p>Dabble in Google Cloud</p>
            <br></br>
            <p>This website is a SPA built in React + CDK, and hosted on AWS</p>
        </div>
    )
    }
export default Tech