import React from "react"

function About() {
    const styles = {
        "padding-left": "300px",
        "padding-right": "300px",
        "text-align": "left"
    }
    return (
        <div>
            <h1>About</h1>            
            <p style={styles}>
                Hi, I'm Lauren! I'm a fullstack software engineer. 
                <br></br>
                <br></br>
                I'm currently a fullstack engineer at an e-commerce site. Our focus is currently modernizing and
                developing serverless services for our application. 
                <br></br>
                <br></br>
                I'm passionate about renewable energy. I'm interested in cleantech and the electrification
                of our world, and interested in how software and technology can combat climate change.
                <br></br>
                <br></br>
                My hobbies include ski mountaineering, mountain biking, running, cooking/baking, and reading
            </p>
        </div>
    )
    }
export default About